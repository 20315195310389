<template>
  <div id="feature-type-edit">
    <div class="fourteen wide column">
      <div
        :class="{ active: loading }"
        class="ui inverted dimmer"
      >
        <div class="ui loader" />
      </div>
      <form
        v-if="project"
        id="form-type-edit"
        class="ui form"
      >
        <h1 v-if="action === 'create'">
          Créer un nouveau type de signalement pour le projet "{{
            project.title
          }}"
        </h1>
        <h1 v-if="feature_type && action === 'edit'">
          Éditer le type de signalement "{{ feature_type.title }}" pour le
          projet "{{ project.title }}"
        </h1>
        <p v-if="action === 'create'">
          Ces champs par défaut existent pour tous les types de signalement:
        </p>

        <div class="two fields">
          <div class="required field">
            <label :for="form.title.id_for_label">{{ form.title.label }}</label>
            <input
              :id="form.title.id_for_label"
              v-model="form.title.value"
              type="text"
              required
              :maxlength="form.title.field.max_length"
              :name="form.title.html_name"
              @blur="updateStore"
            >
            <ul
              id="errorlist"
              class="errorlist"
            >
              <li
                v-for="err in form.title.errors"
                :key="err"
              >
                {{ err }}
              </li>
            </ul>
          </div>

          <div
            id="geometry-type"
            :class="['required field', { disabled: csv }]"
          >
            <label :for="form.geom_type.id_for_label">{{
              form.geom_type.label
            }}</label>
            <Dropdown
              :options="geomTypeChoices"
              :selected="selectedGeomType"
              :selection.sync="selectedGeomType"
            />
          </div>
        </div>
        <div class="field">
          <div class="ui checkbox">
            <input
              :id="form.title_optional.html_name"
              v-model="form.title_optional.value"
              class="hidden"
              :name="form.title_optional.html_name"
              type="checkbox"
            >
            <label :for="form.title_optional.html_name">{{ form.title_optional.label }}</label>
          </div>
        </div>
        <div class="field">
          <div class="ui checkbox">
            <input
              :id="form.enable_key_doc_notif.html_name"
              v-model="form.enable_key_doc_notif.value"
              class="hidden"
              :name="form.enable_key_doc_notif.html_name"
              type="checkbox"
            >
            <label :for="form.enable_key_doc_notif.html_name">{{ form.enable_key_doc_notif.label }}</label>
          </div>
        </div>
        <div class="field">
          <div class="ui checkbox">
            <input
              :id="form.disable_notification.html_name"
              v-model="form.disable_notification.value"
              class="hidden"
              :name="form.disable_notification.html_name"
              type="checkbox"
            >
            <label :for="form.disable_notification.html_name">{{ form.disable_notification.label }}</label>
          </div>
        </div>

        <div id="formsets">
          <FeatureTypeCustomForm
            v-for="customForm in customForms"
            :key="customForm.dataKey"
            ref="customForms"
            :data-key="customForm.dataKey"
            :custom-form="customForm"
            :selected-color-style="form.colors_style.value.custom_field_name"
            @update="updateColorsStyle($event)"
          />
        </div>

        <button
          id="add-field"
          type="button"
          class="ui compact basic button"
          @click="addCustomForm"
        >
          <i
            class="ui plus icon"
            aria-hidden="true"
          />
          Ajouter un champ personnalisé
        </button>

        <div class="ui divider" />
        <button
          id="send-feature_type"
          :class="['ui teal icon button margin-25', { disabled: loading }]"
          type="button"
          @click="sendFeatureType"
        >
          <i
            class="white save icon"
            aria-hidden="true"
          />
          {{ action === "create" ? "Créer" : "Sauvegarder" }} le type de
          signalement
        </button>
        <button
          v-if="geojson || csv || json"
          :class="['ui teal icon button margin-25', { disabled: loading }]"
          type="button"
          @click="postFeatureTypeThenFeatures"
        >
          <i
            class="white save icon"
            aria-hidden="true"
          />
          Créer et importer le(s) signalement(s) du {{ geojson ? 'geojson' : csv ? 'csv' : 'json' }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>

import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';

import Dropdown from '@/components/Dropdown.vue';
import FeatureTypeCustomForm from '@/components/FeatureType/FeatureTypeCustomForm.vue';
import { transformProperties, reservedKeywords } from'@/utils';

export default {
  name: 'FeatureTypeEdit',

  components: {
    Dropdown,
    FeatureTypeCustomForm,
  },

  props: {
    geojson: {
      type: Object,
      default: null,
    },
    csv: {
      type: Array,
      default: null,
    },
    json: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      action: 'create',
      dataKey: 0,
      csvFields: null,
      geomTypeChoices: [
        { value: 'linestring', name: 'Ligne' },
        { value: 'point', name: 'Point' },
        { value: 'polygon', name: 'Polygone' },
        { value: 'none', name: 'Aucune' },
      ],
      form: {
        colors_style: {
          fields: [],
          value: {
            colors: {},
            custom_field_name: '',
          },
        },
        color: {
          id_for_label: 'couleur',
          label: 'Couleur',
          field: {
            max_length: 128, // ! Vérifier la valeur dans django
          },
          html_name: 'couleur',
          value: '#000000',
        },
        title: {
          errors: [],
          id_for_label: 'title',
          label: 'Titre',
          field: {
            max_length: 128,
          },
          html_name: 'title',
          value: null,
        },
        title_optional: {
          errors: null,
          id_for_label: 'title_optional',
          html_name: 'title_optional',
          label: 'Titre du signalement optionnel',
          value: false,
        },
        enable_key_doc_notif: {
          errors: null,
          id_for_label: 'enable_key_doc_notif',
          html_name: 'enable_key_doc_notif',
          label: 'Activer la notification de publication de pièces jointes',
          value: false,
        },
        disable_notification: {
          errors: null,
          id_for_label: 'disable_notification',
          html_name: 'disable_notification',
          label: 'Désactiver les notifications',
          value: false,
        },
        geom_type: {
          id_for_label: 'geom_type',
          label: 'Type de géométrie',
          field: {
            max_length: 128,
          },
          html_name: 'geom_type',
          value: 'point',
        },
      },
      slug: this.$route.params.slug,
    };
  },

  computed: {
    ...mapState('projects', [
      'project'
    ]),
    ...mapState('feature-type', [
      'customForms',
      'colorsStyleList',
      'fileToImport'
    ]),
    ...mapGetters('feature-type', [
      'feature_type'
    ]),
    
    selectedGeomType: {
      get() {
        const currentGeomType = this.geomTypeChoices.find(
          (el) => el.value === this.form.geom_type.value
        );
        if (currentGeomType) {
          return currentGeomType ? currentGeomType.name : null;
        }
        return null;
      },
      set(newValue) {
        this.form.geom_type.value = newValue.value;
        this.form = { ...this.form }; // ! quick & dirty fix for getter not updating because of Vue caveat https://vuejs.org/v2/guide/reactivity.html#For-Objects
        this.updateStore();
      },
    },
    selected_colors_style: {
      get() {
        const name = this.form.colors_style.value.custom_field_name;
        const customField = this.customForms.find((el) => el.name === name);
        return customField && customField.length !== 0
          ? customField.label
          : name;
      },
      set(newValue) {
        //* update only if different than custom_form
        if (newValue.value !== this.form.colors_style.value.custom_field_name) {
          //* get back values from original feature_type
          if (
            this.feature_type && //* if the feature_type exists already
            newValue.value === this.feature_type.colors_style.custom_field_name
          ) {
            this.form.colors_style.value = this.feature_type.colors_style;
          } else {
            const newColorsStyle = {
              colors: newValue.options.reduce((obj, key) => {
                obj[key] = '#000000';
                return obj;
              }, {}),
            };
            this.form.colors_style.value = newColorsStyle;
            this.updateStore();
          }
        }
        this.form.colors_style.value.custom_field_name = newValue.value;
        this.form = { ...this.form }; //! force reactivity to update custom_field_name
      },
    },
  },

  watch: {
    feature_type(newValue) {
      if (newValue) {
        this.fillFormData(newValue);
      }
    },
    customForms(newValue, oldValue) {
      if (newValue !== oldValue) {
        // Retrieve custom_field_name; returns undefined if colors_style.value is null/undefined
        const customFieldName = this.form.colors_style.value?.custom_field_name;

        // Determine if a custom field with the given name exists in customForms
        // 'some' returns true if any element matches the condition
        const customFieldExists = customFieldName && this.customForms.some(el => el.name === customFieldName);

        // Reset colors_style if no corresponding custom field is found
        if (!customFieldExists) {
          this.form.colors_style.value = { colors: {}, custom_field_name: '' };
        }
      }
    },
  },

  created() {
    if (!this.project) {
      this.$store.dispatch('projects/GET_PROJECT', this.$route.params.slug);
      this.$store.dispatch('projects/GET_PROJECT_INFO', this.$route.params.slug);
    }
    this.SET_CURRENT_FEATURE_TYPE_SLUG(this.$route.params.slug_type_signal);

    this.definePageType();
  },

  mounted() {
    if (this.action === 'edit' || this.action === 'duplicate') {
      if (this.feature_type) {
        //* add datas from store to state to avoid mutating directly store with v-model (not good practice), could have used computed with getter and setter as well
        this.fillFormData(this.feature_type);
      }
      if (this.action === 'duplicate') {
        //* replace original name with new default title
        this.form.title.value += ` (Copie-${new Date()
          .toLocaleString()
          .slice(0, -3)
          .replace(',', '')})`;
        this.updateStore(); // * initialize form in store in case this.form would not be modified
      }
    }
    //* when creation from a geojson
    if (this.geojson) {
      this.importGeoJsonFeatureType();
      //* add multiple geometries options available only for geojson (therefore when importing from catalog also)
      this.geomTypeChoices.push(
        { value: 'multilinestring', name: 'Multiligne' },
        { value: 'multipoint', name: 'Multipoint' },
        { value: 'multipolygon', name: 'Multipolygone' },
      );
    }
    if (this.csv) {
      this.importCSVFeatureType();
    }
    if (this.json) {
      this.importJsonFeatureType();
    }
  },
  beforeDestroy() {
    this.EMPTY_FORM();
    this.EMPTY_CUSTOM_FORMS();
    this.SET_FILE_TO_IMPORT(null);
  },

  methods: {
    ...mapMutations([
      'DISPLAY_MESSAGE',
    ]),
    ...mapMutations('feature-type', [
      'ADD_CUSTOM_FORM',
      'EMPTY_FORM',
      'EMPTY_CUSTOM_FORMS',
      'UPDATE_FORM',
      'SET_FILE_TO_IMPORT',
      'SET_CURRENT_FEATURE_TYPE_SLUG',
    ]),
    ...mapActions('feature-type', [
      'SEND_FEATURE_TYPE',
      'SEND_FEATURES_FROM_GEOJSON'
    ]),

    definePageType() {
      if (this.$router.history.current.name === 'ajouter-type-signalement') {
        this.action = 'create';
      } else if (
        this.$router.history.current.name === 'editer-type-signalement'
      ) {
        this.action = 'edit';
      } else if (
        this.$router.history.current.name === 'dupliquer-type-signalement'
      ) {
        this.action = 'duplicate';
      }
    },

    addCustomForm(customForm) {
      if (
        customForm &&
        this.customForms.some((cf) => cf.name === customForm.name)
      ) {
        //* abort if customForm already exists (because watcher can update again)
        return;
      }
      this.dataKey += 1; // * increment counter for key in v-for
      let newCustomForm = {
        dataKey: this.dataKey,
      };
      if (customForm) {
        //* if adding an existing customForm -> add its property to newCustomForm containing only dataKey
        newCustomForm = { ...newCustomForm, ...customForm };
      }
      this.ADD_CUSTOM_FORM(newCustomForm); // * create an object with the counter in store
    },

    fillFormData(formData) {
      for (const el in formData) {
        // * find feature_type and fill form values
        if (this.form[el]) {
          this.form[el].value = formData[el];
        }
      }
      //! add custom fields using ONLY this function, incrementing dataKey for Vue to correctly update components
      [...formData.customfield_set].forEach((el) => this.addCustomForm(el));
      this.updateStore();
    },

    setColorStyles(event) {
      const { name, value } = event.target;
      this.form.colors_style.value.colors[name] = value;
    },

    updateColorsStyle(newOptions) {
      const optionNames = Object.keys(this.form.colors_style.value.colors);
      //* if new value added
      if (newOptions.length > optionNames.length) {
        for (const key of newOptions) {
          if (key && !optionNames.includes(key)) {
            //* check if key is not en empty string
            this.form.colors_style.value.colors[key] = '#000000'; //* add new entry
          }
        }
        //* if modified or deleted
      } else {
        const modifiedColorStyle = {};
        for (const [index, key] of newOptions.entries()) {
          //* if no key then item will disappear (deleted)
          if (key) {
            const values = Object.values(this.form.colors_style.value.colors);
            modifiedColorStyle[key] = values[index]; //* overide key and get previous value from previous colors style
          }
        }
        this.form.colors_style.value.colors = modifiedColorStyle;
      }
    },

    updateStore() {
      this.UPDATE_FORM({
        color: this.form.color,
        title: this.form.title,
        title_optional: this.form.title_optional,
        enable_key_doc_notif: this.form.enable_key_doc_notif,
        disable_notification: this.form.disable_notification,
        geom_type: this.form.geom_type,
        colors_style: this.form.colors_style,
      });
    },

    checkCustomForms() {
      let is_valid = true;
      if (this.$refs.customForms) {
        for (const customForm of this.$refs.customForms) {
          if (customForm.checkCustomForm() === false) {
            is_valid = false;
          }
        }
      }
      return is_valid; //* fallback if all customForms returned true
    },

    checkForms() {
      if (this.form.title.value) {
        this.form.title.errors = [];
        return this.checkCustomForms(); //* if customForms are ok, validate, if get out function
      } else if (
        !this.form.title.errors.includes('Veuillez compléter ce champ.')
      ) {
        this.form.title.errors.push('Veuillez compléter ce champ.');
        document
          .getElementById('errorlist')
          .scrollIntoView({ block: 'end', inline: 'nearest' });
      }
      return false;
    },

    goBackToProject(message) {
      this.$router.push({
        name: 'project_detail',
        params: {
          slug: this.slug,
          message,
        },
      });
    },

    sendFeatureType() {
      // * si édition d'une feature_type déja existante, faire un put
      const requestType = this.action === 'edit' ? 'put' : 'post';
      if (this.checkForms()) {
        this.SEND_FEATURE_TYPE(requestType)
          .then((response) => {
            const { status, data } = response;
            if (status === 200) {
              this.goBackToProject({ comment: 'Le type de signalement a été mis à jour', level: 'positive' });
            } else if (status === 201) {
              this.goBackToProject({ comment: 'Le nouveau type de signalement a été créé', level: 'positive' });
            } else {
              let comment = 'Une erreur est survenue lors de l\'import du type de signalement';
              if (data.customfield_set) {
                let errors =  data.customfield_set.find((el) => el.options);
                if (errors.options) {
                  let customFieldError =  errors.options[0];
                  if(customFieldError) comment = customFieldError[0].replace('ce champ', 'chaque option de champ personnalisé');
                }
              }
              this.DISPLAY_MESSAGE({
                comment,
                level: 'negative'
              });
            }
          });
      }
    },

    postGeojsonFeatures(feature_type_slug) {
      this.SEND_FEATURES_FROM_GEOJSON({
        slug: this.slug,
        feature_type_slug,
        geojson: this.geojson || this.json
      })
        .then((response) => {
          if (response && response.status === 200) {
            this.goBackToProject({
              comment: 'Le nouveau type de signalement a été créé. L\'import des signalements est en cours',
              level: 'positive'
            });
          } else {
            this.DISPLAY_MESSAGE({
              comment: `Une erreur est survenue lors de l'import de signalements.\n ${ response.data.detail }`,
              level: 'negative'
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    postCSVFeatures(feature_type_slug) {
      this.$store
        .dispatch('feature-type/SEND_FEATURES_FROM_CSV', {
          slug: this.slug,
          feature_type_slug,
          csv: this.csv
        })
        .then((response) => {
          if (response && response.status === 200) {
            this.goBackToProject({
              comment: 'Le nouveau type de signalement a été créé. Import des signalements est en cours',
              level: 'positive'
            });
          } else {
            this.DISPLAY_MESSAGE({
              comment: `Une erreur est survenue lors de l'import de signalements.\n ${ response.data.detail }`,
              level: 'negative'
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    async postFeatureTypeThenFeatures() {
      const requestType = this.action === 'edit' ? 'put' : 'post';
      if (this.checkForms()) {
        this.loading = true;
        await this.$store
          .dispatch('feature-type/SEND_FEATURE_TYPE', requestType)
          .then(({ feature_type_slug }) => {
            if (feature_type_slug) {
              if (this.geojson || this.json) {
                this.postGeojsonFeatures(feature_type_slug);
              } else if (this.csv) {
                this.postCSVFeatures(feature_type_slug);
              }
            } else {
              this.loading = false;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },

    // ****** Methodes for geojson import ****** //
    toNewFeatureType() {
      this.$router.push({
        name: 'ajouter-type-signalement',
        params: { geojson: this.jsonDict },
      });
    },

    /**
     * Builds custom form fields based on the properties of data entries.
     *
     * This function iterates through a subset of data entries (such as rows from a CSV, JSON objects, or GeoJSON features)
     * to determine the most appropriate type for each field. It tracks confirmed types to avoid redundant checks and 
     * stops processing a field once its type is definitively determined. If a field is initially detected as a 'char',
     * it remains as 'char' unless a multiline text ('text') is detected later. The function prioritizes the detection 
     * of definitive types (like 'text', 'boolean', 'integer') and updates the form with the confirmed types.
     *
     * @param {Array} propertiesList - An array of data entries, where each entry is an object representing a set of properties.
     */

    buildCustomForm(propertiesList) {
      const confirmedTypes = {};  // Store confirmed types for each field
      const detectedAsChar = {};  // Track fields initially detected as 'char'

      // Iterate over each row or feature in the subset
      propertiesList.forEach((properties) => {
        for (const [key, val] of Object.entries(properties)) {
          if (!reservedKeywords.includes(key)) {

            // If the type for this field has already been confirmed as something other than 'char', skip it
            if (confirmedTypes[key] && confirmedTypes[key] !== 'char') {
              continue;
            }

            // Determine the type of the current value
            const detectedType = transformProperties(val);

            if (detectedType === 'text') {
              // Once 'text' (multiline) is detected, confirm it immediately
              confirmedTypes[key] = 'text';
            } else if (!confirmedTypes[key] && detectedType !== 'char') {
              // If a type is detected that is not 'char' and not yet confirmed, confirm it
              confirmedTypes[key] = detectedType;
            } else if (!confirmedTypes[key]) {
              // If this field hasn't been confirmed yet, initialize it as 'char'
              confirmedTypes[key] = 'char';
              detectedAsChar[key] = true;
            } else if (detectedAsChar[key] && detectedType !== 'char') {
              // If a field was initially detected as 'char' but now has a different type, update it
              confirmedTypes[key] = detectedType;
              delete detectedAsChar[key];  // Remove from 'char' tracking once updated
            }
          }
        }
      });

      // Build custom forms using the confirmed types
      for (const [key, confirmedType] of Object.entries(confirmedTypes)) {
        const customForm = {
          label: { value: key || '' },
          name: { value: key || '' },
          position: this.dataKey, // use dataKey already incremented by addCustomForm
          field_type: { value: confirmedType }, // use the confirmed type
          options: { value: [] }, // not available in export
        };
        this.addCustomForm(customForm);
      }
    },

    setTitleFromFile() {
      if (this.fileToImport && this.fileToImport.name) {
        this.form.title.value = // * use the filename as title by default
          this.fileToImport.name.split('.')[0];
      } else { //* case when the data comes from datasud catalog
        // * use the typename as title by default
        this.form.title.value = this.geojson.name || this.csv.name || this.json.name;
      }
    },

    importGeoJsonFeatureType() {
      if (this.geojson.features && this.geojson.features.length) {
        const { geometry } = this.geojson.features[0];
        this.form.geom_type.value = geometry.type.toLowerCase();
        this.updateStore(); // register geom_type in store

        // Use a subset of the first N features to build the form
        const subsetFeatures = this.geojson.features.slice(0, 200); // Adjust '200' based on performance needs
        const propertiesList = subsetFeatures.map(feature => feature.properties);
        this.buildCustomForm(propertiesList);
      }
      this.setTitleFromFile();
    },

    importCSVFeatureType() {
      if (this.csv.length) {
        this.updateStore(); // register title in store

        // Use a subset of the first N rows to build the form
        const subsetCSV = this.csv.slice(0, 200); // Adjust '200' based on performance needs
        this.buildCustomForm(subsetCSV);

        // Check for geom data
        if (!('lat' in this.csv[0]) || !('lon' in this.csv[0])) {
          this.form.geom_type.value = 'none';
        }
      }
      this.setTitleFromFile();
    },

    importJsonFeatureType() {
      if (this.json.length) {
        this.form.geom_type.value = 'none'; // JSON are non-geom features
        this.updateStore(); // register title in store

        // Use a subset of the first N objects to build the form
        const subsetJson = this.json.slice(0, 200); // Adjust '200' based on performance needs
        this.buildCustomForm(subsetJson);
      }
      this.setTitleFromFile();
    },

  },
};
</script>

<style>
#add-field {
  margin-top: 1em;
}

#id_style_container {
  display: flex;
  height: 100%;
}

#id_colors_selection {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.color-input {
  margin-left: 1em;
}
.color-input > label {
  margin-right: 0.5em;
}

.margin-25 {
  margin: 0 0.25em 0.25em 0 !important;
}

/* // * probleme avec le style récupéré, n'est jamais identique !???? */
#formsets {
  margin-top: 1em !important;
}
</style>