<template>
  <div
    :id="custFormId"
    :class="['ui teal segment pers-field', { hasErrors }]"
  >
    <div class="custom-field-header">
      <h4>
        Champ personnalisé
      </h4>
      <div class="top-right">
        <div
          v-if="(form.label.value || form.name.value) && selectedFieldType !== 'Booléen'"
          class="ui checkbox"
        >
          <input
            v-model="form.is_mandatory.value"
            type="checkbox"
            :name="form.html_name"
            @change="updateStore"
          >
          <label :for="form.html_name">Champ obligatoire
            <span v-if="form.conditional_field_config.value">si actif</span>
          </label>
        </div>
        <button
          class="ui small compact right floated icon button remove-field"
          type="button"
          @click="removeCustomForm()"
        >
          <i
            class="ui times icon"
            aria-hidden="true"
          />
        </button>
      </div>
    </div>
    <div class="visible-fields">
      <div class="two fields">
        <div class="required field">
          <label :for="form.label.id_for_label">{{ form.label.label }}</label>
          <input
            :id="form.label.id_for_label"
            v-model="form.label.value"
            type="text"
            required
            :maxlength="form.label.field.max_length"
            :name="form.label.html_name"
            @input="updateStore"
          >
          <small>{{ form.label.help_text }}</small>
          <ul
            id="errorlist"
            class="errorlist"
          >
            <li
              v-for="error in form.label.errors"
              :key="error"
            >
              {{ error }}
            </li>
          </ul>
        </div>

        <div class="required field">
          <label :for="form.name.id_for_label">{{ form.name.label }}</label>
          <input
            :id="form.name.id_for_label"
            v-model="form.name.value"
            type="text"
            required
            :maxlength="form.name.field.max_length"
            :name="form.name.html_name"
            @input="updateStore"
          >
          <small>{{ form.name.help_text }}</small>
          <ul
            id="errorlist"
            class="errorlist"
          >
            <li
              v-for="error in form.name.errors"
              :key="error"
            >
              {{ error }}
            </li>
          </ul>
        </div>
      </div>

      <div class="three fields">
        <div class="required field">
          <label :for="form.position.id_for_label">{{
            form.position.label
          }}</label>
          <div class="ui input">
            <input
              :id="form.position.id_for_label"
              v-model="form.position.value"
              type="number"
              :min="form.position.field.min_value"
              :name="form.position.html_name"
              @change="updateStore"
            >
          </div>
          <small>{{ form.position.help_text }}</small>
          <ul
            id="errorlist"
            class="errorlist"
          >
            <li
              v-for="error in form.position.errors"
              :key="error"
            >
              {{ error }}
            </li>
          </ul>
        </div>

        <div
          id="field_type"
          class="required field"
        >
          <label :for="form.field_type.id_for_label">{{
            form.field_type.label
          }}</label>
          <Dropdown
            :disabled="!form.label.value || !form.name.value"
            :options="customFieldTypeChoices"
            :selected="selectedFieldType"
            :selection.sync="selectedFieldType"
          />
          <ul
            id="errorlist"
            class="errorlist"
          >
            <li
              v-for="error in form.field_type.errors"
              :key="error"
            >
              {{ error }}
            </li>
          </ul>
        </div>
        <div
          v-if="selectedFieldType === 'Liste de valeurs pré-enregistrées'"
          class="field required"
          data-test="prerecorded-list-option"
        >
          <label>{{
            form.options.label
          }}</label>
          <Dropdown
            :options="preRecordedLists"
            :selected="arrayOption"
            :selection.sync="arrayOption"
          />
          <ul
            id="errorlist"
            class="errorlist"
          >
            <li
              v-for="error in form.options.errors"
              :key="error"
            >
              {{ error }}
            </li>
          </ul>
        </div>
      </div>

      <div
        v-if="selectedFieldType === 'Liste de valeurs' || selectedFieldType === 'Liste à choix multiples'"
        class="field field-list-options required field"
      >
        <label :for="form.options.id_for_label">{{
          form.options.label
        }}</label>
        <div>
          <div :id="`list-options-${customForm.dataKey}`">
            <div
              v-for="(option, index) in form.options.value"
              :id="option"
              :key="`${option}-${index}`"
              class="draggable-row"
            >
              <i
                class="th icon grey"
                aria-hidden="true"
              />
              <input
                :value="option"
                type="text"
                :maxlength="form.options.field.max_length"
                :name="form.options.html_name"
                class="options-field"
                @change="updateOptionValue(index, $event)"
              >
              <i 
                class="trash icon grey"
                @click="deleteOption(index)"
              />
            </div>
          </div>
          <div class="ui buttons">
            <a
              class="ui compact small icon left floated button teal basic"
              @click="addOption"
            >
              <i
                class="ui plus icon"
                aria-hidden="true"
              />
              <span>&nbsp;Ajouter une option</span>
            </a>
          </div>
        </div>
        <ul
          id="errorlist"
          class="errorlist"
        >
          <li
            v-for="error in form.options.errors"
            :key="error"
          >
            {{ error }}
          </li> 
        </ul>
      </div>

      <div class="conditional-blocks">
        <div class="ui checkbox">
          <input
            type="checkbox"
            name="conditional-custom-field"
            :checked="form.conditional_field_config.value"
            @change="setConditionalCustomForm"
          >
          <label
            class="pointer"
            for="conditional-custom-field"
            @click="setConditionalCustomForm"
          >
            Activation conditionnelle
          </label>
        </div>

        <div
          v-if="form.conditional_field_config.value !== null && form.conditional_field_config.value !== undefined"
          id="condition-field"
        >
          <h5>Condition d'apparition&nbsp;:</h5>
          <CustomFormConditionalField
            :custom-form="customForm"
            :custom-forms="customForms"
            :config="form.conditional_field_config.value"
            @update:config="setConditionalFieldConfig($event)"
          />
          <ul
            id="errorlist"
            class="errorlist"
          >
            <li
              v-for="error in form.conditional_field_config.errors"
              :key="error"
            >
              {{ error }}
            </li>
          </ul>
        </div>
      </div>

      <div class="conditional-blocks">
        <div
          v-for="(config, index) in form.forced_value_config.value"
          :id="`forced-value-${index}`"
          :key="`forced-value-${config.dataKey}`"
        >
          <h5>Condition à valeur forcée&nbsp;{{ index + 1 }}&nbsp;:</h5>
          <div class="inline">
            <CustomFormConditionalField
              :form="form"
              :stored-custom-form="customForm"
              :custom-forms="customForms"
              :config="config"
              :is-forced-value="true"
              @update:config="setForcedValue($event)"
            />
            <i 
              class="trash icon grey"
              @click="deleteForcedValue(config.dataKey)"
            />
          </div>
        </div>
        <ul
          id="errorlist"
          class="errorlist"
        >
          <li
            v-for="error in form.forced_value_config.errors"
            :key="error"
          >
            {{ error }}
          </li>
        </ul>
        <button
          id="add-forced-value"
          class="ui compact basic button"
          @click.prevent="addForcedValue"
        >
          <i
            class="ui plus icon"
            aria-hidden="true"
          />
          Ajouter une valeur forcée selon la valeur d'un autre champ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Sortable from 'sortablejs';

import { customFieldTypeChoices, reservedKeywords } from '@/utils';
import Dropdown from '@/components/Dropdown.vue';
import CustomFormConditionalField from '@/components/FeatureType/CustomFormConditionalField.vue';

export default {
  name: 'FeatureTypeCustomForm',

  components: {
    Dropdown,
    CustomFormConditionalField,
  },

  props: {
    customForm: {
      type: Object,
      default: null,
    },
    selectedColorStyle: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      customFieldTypeChoices,
      form: {
        is_mandatory: {
          value: false,
          html_name: 'mandatory-custom-field',
        },
        label: {
          errors: [],
          id_for_label: 'label',
          label: 'Label',
          help_text: 'Nom en language naturel du champ',
          html_name: 'label',
          field: {
            max_length: 256,
          },
          value: null,
        },
        name: {
          errors: [],
          id_for_label: 'name',
          label: 'Nom',
          html_name: 'name',
          help_text:
            "Nom technique du champ tel qu'il apparaît dans la base de données ou dans l'export GeoJSON. Seuls les caractères alphanumériques et les traits d'union sont autorisés: a-z, A-Z, 0-9, _ et -)",
          field: {
            max_length: 128,
          },
          value: null,
        },
        position: {
          errors: [],
          id_for_label: 'position',
          label: 'Position',
          min_value: 0, // ! check if good values (not found)
          html_name: 'position',
          help_text:
            "Numéro d'ordre du champ dans le formulaire de saisie du signalement",
          field: {
            max_length: 128, // ! check if good values (not found)
          },
          value: this.customForm.dataKey - 1,
        },
        field_type: {
          errors: [],
          id_for_label: 'field_type',
          label: 'Type de champ',
          html_name: 'field_type',
          help_text: '',
          field: {
            max_length: 50,
          },
          value: 'boolean',
        },
        options: {
          errors: [],
          id_for_label: 'options',
          label: 'Options',
          html_name: 'options',
          help_text: 'Valeurs possibles de ce champ, séparées par des virgules',
          field: {
            max_length: null,
          },
          value: [],
        },
        conditional_field_config: {
          errors: [],
          value: null,
        },
        forced_value_config: {
          errors: [],
          value: [],
        }
      },
      hasErrors: false,
      selectedPrerecordedList: null,
      sortable: null
    };
  },

  computed: {
    ...mapState('feature-type', [
      'customForms',
      'preRecordedLists'
    ]),
    custFormId() {
      return `custom_form-${this.form.position.value}`;
    },
    selectedFieldType: {
      // getter
      get() {
        const currentFieldType = customFieldTypeChoices.find(
          (el) => el.value === this.form.field_type.value
        );
        if (currentFieldType) {
          this.$nextTick(() => { // to be executed after the fieldType is returned and the html generated
            if (this.selectedFieldType === 'Liste de valeurs' || this.selectedFieldType === 'Liste à choix multiples') {
              this.initSortable();
            }
          });
          return currentFieldType.name;
        }
        return null;
      },
      // setter
      set(newValue) {
        if (newValue.value === 'pre_recorded_list') {
          this.GET_PRERECORDED_LISTS();
        }
        this.form.field_type.value = newValue.value;
        this.form = { ...this.form }; // ! quick & dirty fix for getter not updating because of Vue caveat https://vuejs.org/v2/guide/reactivity.html#For-Objects
        // Vue.set(this.form.field_type, "value", newValue.value); // ? vue.set didn't work, maybe should flatten form ?
        this.updateStore();
      },
    },
    arrayOption: {
      get() {
        return this.form.options.value.join();
      },
      // * create an array, because backend expects an array
      set(newValue) {
        this.form.options.value = this.trimWhiteSpace(newValue).split(',');
        if (!this.hasDuplicateOptions()) {
          this.updateStore();
        }
      },
    },
    forcedValMaxDataKey() { // get the highest datakey value in forced value configs...
      return this.form.forced_value_config.value.reduce( // ...used to increment new config datakey
        (maxDataKey, currentConfig) => (maxDataKey > currentConfig.dataKey) ?
          maxDataKey : currentConfig.dataKey, 1
      );
    },
  },

  mounted() {
    //* add datas from store to state to avoid mutating directly store with v-model (not good practice), could have used computed with getter and setter as well
    this.fillCustomFormData(this.customForm);
    if (this.customForm.field_type === 'pre_recorded_list') {
      this.GET_PRERECORDED_LISTS();
    }
  },

  methods: {
    ...mapActions('feature-type', [
      'GET_PRERECORDED_LISTS'
    ]),

    hasDuplicateOptions() {
      this.form.options.errors = [];
      const isDup =
        new Set(this.form.options.value).size !==
        this.form.options.value.length;
      if (isDup) {
        this.form.options.errors = ['Veuillez saisir des valeurs différentes'];
        return true;
      }
      return false;
    },

    fillCustomFormData(customFormData) {
      for (const el in customFormData) {
        if (el && this.form[el] && customFormData[el] !== undefined && customFormData[el] !== null) {
          //* check if is an object, because data from api is a string, while import from django is an object
          this.form[el].value = customFormData[el].value
            ? customFormData[el].value
            : customFormData[el];
        }
      }
      this.updateStore();
    },

    removeCustomForm() {
      this.$store.commit(
        'feature-type/REMOVE_CUSTOM_FORM',
        this.customForm.dataKey
      );
    },
        
    initSortable() {
      this.sortable = new Sortable(document.getElementById(`list-options-${this.customForm.dataKey}`), {
        animation: 150,
        handle: '.draggable-row', // The element that is active to drag
        ghostClass: 'blue-background-class',
        dragClass: 'white-opacity-background-class',
        onEnd: this.updateOptionOrder,
        filter: 'input',        // prevent input field not clickable...
        preventOnFilter: false, // ... on element inside sortable
      });
    },

    setConditionalFieldConfig(config) {
      this.form.conditional_field_config.value = config;
      this.updateStore();
    },

    setConditionalCustomForm() {
      if (this.form.conditional_field_config.value === null) {
        // retrieve existing value when the user uncheck and check again, if no value defined create an empty object
        this.form.conditional_field_config.value = this.customForm.conditional_field_config || {};
      } else {
        this.form.conditional_field_config.value = null;
      }
      this.updateStore();
    },
    
    addForcedValue() {
      const newForcedValueConfig = {
        dataKey: this.forcedValMaxDataKey + 1,
        // forced value can already be set here, setting false for boolean because user won't select if he wants to set on false, thus avoiding the null value not passing form check
        forcedValue: this.form.field_type.value === 'boolean' ? false : null
      };
      this.form.forced_value_config.value = [...this.form.forced_value_config.value, newForcedValueConfig];
    },
    
    setForcedValue(newConfig) {
      this.form.forced_value_config.value = this.form.forced_value_config.value.map((config) => {
        return config.dataKey === newConfig.dataKey ? newConfig : config;
      });
      this.updateStore();
    },
    
    deleteForcedValue(dataKey) {
      this.form.forced_value_config.value = this.form.forced_value_config.value.filter(
        (config) => config.dataKey !== dataKey
      );
      this.updateStore();
    },

    updateStore() {
      const data = {
        dataKey: this.customForm.dataKey,
        is_mandatory: this.form.is_mandatory.value,
        label: this.form.label.value,
        name: this.form.name.value,
        position: this.form.position.value,
        field_type: this.form.field_type.value,
        options: this.form.options.value,
        conditional_field_config: this.form.conditional_field_config.value,
        forced_value_config: this.form.forced_value_config.value,
      };
      this.$store.commit('feature-type/UPDATE_CUSTOM_FORM', data);
      if (this.customForm.name === this.selectedColorStyle ) {
        this.$emit('update', this.form.options.value);
      }

      // if there was any error, check if the update fixed it and update errors displayed
      if (this.hasErrors) {
        this.checkCustomForm(true);
      }
    },

    updateOptionValue(index, e) {
      this.form.options.value[index] = e.target.value;
      if (!this.hasDuplicateOptions()) {
        this.updateStore();
      }
    },

    updateOptionOrder(e) {
      const currentOptionsList = Array.from(e.target.childNodes).map((el) => el.id);
      this.form.options.value = currentOptionsList;
      this.updateStore();
    },
    
    addOption() {
      this.form.options.value.push('');
    },

    deleteOption(index) {
      this.form.options.value.splice(index, 1);
    },

    trimWhiteSpace(string) {
      // TODO : supprimer les espaces pour chaque option au début et à la fin QUE à la validation
      return string.replace(/\s*,\s*/gi, ',');
    },

    //* CHECKS *//
    hasRegularCharacters(input) {
      for (const char of input) {
        if (!/[a-zA-Z0-9-_]/.test(char)) {
          return false;
        }
      }
      return true;
    },

    /**
     * Ensures the name entered in the form is unique among all custom field names.
     * This function prevents duplicate names, including those with only case differences, 
     * to avoid conflicts during automatic view generation where names are slugified.
     *
     * @returns {boolean} - Returns true if the name is unique (case insensitive), false otherwise.
     */
    checkUniqueName() {
      const occurences = this.customForms
        .map((el) => el.name.toLowerCase())
        .filter((el) => el === this.form.name.value.toLowerCase());
      return occurences.length === 1;
    },

    checkOptions() {
      if (this.form.field_type.value === 'list') {
        return this.form.options.value.length >= 2 && !this.form.options.value.includes('') ?
          '' : 'Veuillez renseigner au moins 2 options.';
      }
      if (this.form.field_type.value === 'pre_recorded_list') {
        return this.form.options.value.length === 1 ?
          '' : 'Veuillez sélectionner une option.';
      }
      return '';
    },

    isConditionFilled(condition) {
      if (condition) {
        return condition.conditionField && condition.conditionValue !== null && condition.conditionValue !== undefined;
      }
      return true;
    },
    
    isForcedValueFilled() {
      if (this.form.forced_value_config.value) {
        for (const config of this.form.forced_value_config.value) {
          if (!this.isConditionFilled(config) || config.forcedValue === null || config.forcedValue === undefined) {
            return false;
          }
        }
      }
      return true;
    },

    checkCustomForm(noScroll) {
      // reset errors to empty array
      for (const element in this.form) {
        if (this.form[element].errors) this.form[element].errors = [];
      }
      // check each form element
      const optionError = this.checkOptions();
      let isValid = true;
      if (!this.form.label.value) {
        //* vérifier que le label est renseigné
        this.form.label.errors = ['Veuillez compléter ce champ.'];
        isValid = false;
      } else if (!this.form.name.value) {
        //* vérifier que le nom est renseigné
        this.form.name.errors = ['Veuillez compléter ce champ.'];
        isValid = false;
      } else if (!this.hasRegularCharacters(this.form.name.value)) {
        //* vérifier qu'il n'y a pas de caractères spéciaux
        this.form.name.errors = [
          'Veuillez utiliser seulement les caratères autorisés.',
        ];
        isValid = false;
      } else if (reservedKeywords.includes(this.form.name.value)) {
        //* vérifier que le nom du champs ne soit pas un nom réservé pour les propriétés standards d'un signalement
        this.form.name.errors = [
          'Ce nom est réservé, il ne peut pas être utilisé',
        ];
        isValid = false;
      } else if (!this.checkUniqueName()) {
        //* vérifier si les noms sont pas dupliqués
        this.form.name.errors = [
          'Les champs personnalisés ne peuvent pas avoir des noms similaires.',
        ];
        isValid = false;
      } else if (optionError) {
        //* s'il s'agit d'un type liste, vérifier que le champ option est bien renseigné
        this.form.options.errors = [optionError];
        isValid = false;
      } else if (this.hasDuplicateOptions()) {
        //* pour le cas d'options dupliqués
        isValid = false;
      } else if (!this.isConditionFilled(this.form.conditional_field_config.value)) {
        //* vérifier si les deux formulaires de l'activation conditionnelle sont bien renseignés
        isValid = false;
        this.form.conditional_field_config.errors = ['Veuillez renseigner tous les champs de la condition ou la désactiver'];
      } else if (!this.isForcedValueFilled()) {
        //* vérifier si les trois formulaires de chaque valeur forcée sont bien renseignés
        isValid = false;
        this.form.forced_value_config.errors = ['Veuillez renseigner tous les champs de chaque condition à valeur forcée ou supprimer celle(s) incomplète(s)'];
      }
      this.hasErrors = !isValid;
      if (!isValid && !noScroll) { // if errors were found: scroll to display this customForm
        document.getElementById(this.custFormId).scrollIntoView();
      }
      return isValid;
    },
  },
};
</script>

<style lang="less" scoped>
.hasErrors {
  border-color: red;
}
.errorlist {
  margin: .5rem 0;
  display: flex;
}
.custom-field-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .top-right {
    display: flex;
    align-items: center;

    .checkbox {
      margin-right: 5rem;
    }

    span {
      color: #666666;
    }
  }
}

i.icon.trash {
  transition: color ease .3s;
}
  i.icon.trash:hover {
  cursor: pointer;
  color: red !important;
}
.conditional-blocks {
  margin: .5em 0;
  h5 {
    margin: 1em 0;
  }
  .inline {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
#condition-field {
  padding-left: 2em;
}
.draggable-row {
  display: flex;
  align-items: baseline;
  margin-bottom: 1em;
  input {
    margin: 0 .5em !important;
  }
}
.segment { // keep custom form scrolled under the app header
  scroll-margin-top: 5rem;
}
</style>
